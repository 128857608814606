import { PostType } from '@space-cow-media/wp-components'

import ArticlePreview from '../ArticlePreview/ArticlePreview'

import ArticlePagination from './ArticlePagination'

type Props = {
  pageCount: number
  pageNum: number
  path: string
  posts: PostType[]
}

const ArticleTypePage = ({ pageCount, pageNum, path, posts }: Props) => (
  <>
    <div className='d-flex flex-column gap-3'>
      {posts.map((post) => (
        <ArticlePreview
          author={post.author}
          date={post.date}
          excerpt={post.excerpt}
          featuredImageUrl={post.featuredImageUrl}
          key={post.slug}
          slug={post.slug}
          tags={post.tags}
          title={post.title}
        />
      ))}
    </div>
    <ArticlePagination pageCount={pageCount} pageNum={pageNum} path={path} />
  </>
)

export default ArticleTypePage
