import { useRouter } from 'next/router'
import Pagination from 'react-bootstrap/Pagination'

import styles from './ArticlePagination.module.scss'

type Props = {
  pageCount: number
  pageNum: number
  path: string
}

export const ArticlePagination = ({ pageCount, pageNum, path }: Props) => {
  const router = useRouter()
  const range = 2 // Max number of pages to either side of the current page

  if (pageCount < 2) {
    return null
  }

  const PaginationItem = ({ children, ...props }: any) => (
    <Pagination.Item
      onClick={(e) => {
        e.preventDefault()
        if (props.href) router.push(props.href)
      }}
      {...props}
    >
      {children}
    </Pagination.Item>
  )

  const pageRange = (limit: number) => {
    const dist = Math.abs(limit - pageNum)
    const length = dist < range ? dist : range
    return Array.from({ length: length }, (_value, index) => index + 1)
  }

  return (
    <Pagination className={styles.pagination}>
      {pageNum > range + 1 && <PaginationItem href={`/articles/${path}/1`}>1</PaginationItem>}
      {pageCount > range + 2 && pageNum > range + 1 && <Pagination.Ellipsis disabled />}
      {pageRange(1)
        .reverse()
        .map((i) => (
          <PaginationItem active={false} href={`/articles/${path}/${pageNum - i}`} key={pageNum - i}>
            {pageNum - i}
          </PaginationItem>
        ))}
      <PaginationItem active key={pageNum}>
        {pageNum}
      </PaginationItem>
      {pageRange(pageCount).map((i) => (
        <PaginationItem active={false} href={`/articles/${path}/${pageNum + i}`} key={pageNum + i}>
          {pageNum + i}
        </PaginationItem>
      ))}
      {pageCount > range + 2 && range + pageNum < pageCount && <Pagination.Ellipsis disabled />}
      {pageCount > range && range + pageNum < pageCount && (
        <PaginationItem href={`/articles/${path}/${pageCount}`}>{pageCount}</PaginationItem>
      )}
    </Pagination>
  )
}

export default ArticlePagination
