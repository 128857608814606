import { useRouter } from 'next/router'
import { SyntheticEvent, useState } from 'react'
import { InputGroup } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

import Icon from '@/components/Icon/Icon'

import styles from './ArticleSearch.module.scss'

type Props = {
  initSearchTerm?: string
}

const ArticleSearch = ({ initSearchTerm }: Props) => {
  const [searchTerm, setSearchTerm] = useState(initSearchTerm)
  const router = useRouter()

  const handleSearch = (e: SyntheticEvent) => {
    e.preventDefault()
    router.push({ pathname: `/articles/search/${searchTerm}` })
  }

  return (
    <div className='d-flex flex-grow-1'>
      <form className={styles.search} onSubmit={handleSearch}>
        <InputGroup>
          <Form.Control
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='Search Articles'
            value={searchTerm}
          />
          <InputGroup.Text>
            <Icon icon='magnifyingGlass' />
          </InputGroup.Text>
        </InputGroup>
      </form>
    </div>
  )
}

export default ArticleSearch
