import { formatTag, TagType } from '@space-cow-media/wp-components'
import Link from 'next/link'

import styles from './Tags.module.scss'

type Props = {
  tags: TagType[]
}

const Tags = ({ tags }: Props) => (
  <div className={styles.tags}>
    {tags.map((tag, i) => (
      <span className='me-2' key={`tag-${i}`}>
        <Link
          dangerouslySetInnerHTML={{ __html: formatTag(tag.name) }}
          href={`/articles/tag/${tag.slug}`}
          prefetch={false}
        />
        {i !== tags.length - 1 && <span>, </span>}
      </span>
    ))}
  </div>
)

export default Tags
