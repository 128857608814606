import classNames from 'classnames'
import Link from 'next/link'

import OutboundLink from '@/components/OutboundLink/OutboundLink'

import styles from './FeaturedImage.module.scss'

type Props = {
  cover?: boolean
  height: number | 'auto'
  slug?: string
  src: string
  title: string
  width: number | 'auto'
}

const FeaturedImage = ({ cover, height, slug, src, title, width }: Props) => {
  const ImageComponent = slug ? Link : OutboundLink
  const imageProps = slug ? { href: `/articles/${slug}` } : { url: src }

  return (
    <div className={classNames({ 'm-auto': !cover })}>
      {/* @ts-ignore */}
      <ImageComponent {...imageProps}>
        <img
          alt={title}
          className={classNames(cover ? styles.cover : styles.image)}
          height={height === 'auto' ? undefined : height}
          src={src}
          width={width === 'auto' ? undefined : width}
        />
      </ImageComponent>
    </div>
  )
}

export default FeaturedImage
